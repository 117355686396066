<template>
	<div>
		<div class="filter-container" style="background: #fff;padding: 15px;">
			<div class="filter-item">
				<label class="label">关键字: </label>
				<el-input v-model="searchKey" placeholder="请输入充值编号" @keyup.enter.native="goodsSearch" style="width: 250px;margin-right: 10px;"
				 clearable></el-input>
			</div>
			<div class="filter-item">
				<label class="label">充值状态: </label>
				<el-select v-model="changeType" style="width: 150px;margin-right: 10px;">
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="item in changeTypeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
			</div>
			<div class="filter-item">
				<label class="label">变更时间: </label>
				<el-date-picker v-model="TimeValue" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange"
				 range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['00:00:00','23:59:59']">
				</el-date-picker>
				<el-button type="primary" style="margin-left:30px;width:90px;" @click="goodsSearch">查询</el-button>
				<el-button type="" style="margin-left:30px;width:90px;" @click="handleExport">导出</el-button>
			</div>
		</div>
		<div class="table-container" style="background: #fff;padding:0 15px 15px 15px;">
			<el-table :data="groupData" style="width: 100%;" v-loading="loading">
				<el-table-column prop="AddTime" label="时间"></el-table-column>
				<el-table-column prop="RecordStateStr" label="充值状态"></el-table-column>
				<el-table-column prop="RechargeMoney" label="充值金额"></el-table-column>
				<el-table-column prop="RecordNo" label="充值编号"></el-table-column>
				<el-table-column prop="Remark" label="备注"></el-table-column>
				<el-table-column label="操作" width='370px'>
					<template slot-scope="scope">
						<el-button type='text' @click='CheckDetails(scope.row.Id)'>查看</el-button>
						<!-- <el-button type='text'>修改</el-button> -->
						<!-- <el-tooltip class="item-tooltip" popper-class="myaccout-note-detail-tooltip" effect="light" :content="scope.row.Remark"
						 placement="top-start">
							<div class="note-detail">
								{{scope.row.Remark}}
							</div>
						</el-tooltip> -->
					</template>
				</el-table-column>
			</el-table>
			<div style="display:flex;justify-content:space-between;align-items:center;">
				<div style="display:flex;font-size: 12px;color: #606266;align-items:center;margin-left: 13px;">
				</div>
				<el-pagination v-if="Total" style="margin-top:20px;float:right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
				 :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
				 :total="Total">
				</el-pagination>
			</div>
		</div>

		<el-dialog title="充值详情" :visible.sync="PaydetailShow" width="500px" v-loading='accountLoading'>
			<div class="Paydetails">
				
				<div>充值编号：{{accoutInfo.RecordNo}}</div>
				<div>收款方户名：{{accoutInfo.BankCardHolder}}</div>
				<div>收款方开户行：{{accoutInfo.BankName}}</div>
				<div>收款方账户：{{accoutInfo.BankCardNo}}</div>
				<div>申请时间：{{accoutInfo.AddTime}}</div>
				<div>充值状态：{{accoutInfo.RecordStateStr}}</div>
				<div>充值金额：{{accoutInfo.RechargeMoney}}</div>
				<div>汇款户名：{{accoutInfo.RechargeBankCardHolder}}</div>
				<div>汇款账户：{{accoutInfo.RechargeBankCardNo}}</div>
				<div>汇款日期：{{accoutInfo.RemittanceDate}}</div>
				<div style="display: flex;justify-content: flex-start"><span>汇款凭证：</span><img @click='Imgurlbigshow' :src="imgUrl + accoutInfo.RechargeImgUrl" style="width: 145px;height: 145px;"></div>
			</div>
		</el-dialog>
		<el-dialog :visible.sync="ImgurlShow" width="500px">
			<div style="text-align: center;">
				<img v-if="accoutInfo.RechargeImgUrl" :src="imgUrl + accoutInfo.RechargeImgUrl"  style="width:300px;"/>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import config from '@/config/index'
	import {
		balanceRechargeRecordlist,
		balanceRechargeRecordInfo
	} from '@/api/TurnTomySelf'
	export default {
		data() {
			return {
				ImgurlShow:false,
				imgUrl: config.IMG_BASE,
				PaydetailShow: false,
				accountLoading: false,
				exportUrl: config.EXPORT_URL,
				searchKey: '',
				changeType: null,
				changeTypeList: [{
					value: 1,
					label: '待审核'
				}, {
					value: 2,
					label: '充值成功'
				}, {
					value: 3,
					label: '充值失败'
				}],
				TimeValue: [],
				groupData: [],
				currentPage: 1,
				pageSize: 20,
				Total: 1,
				loading: false,
				accoutInfo: {}
			}
		},
		created() {
			this.getList();
		},
		methods: {
			Imgurlbigshow(){
				this.ImgurlShow = true
			},
			//查看详情
			async CheckDetails(Id) {
				try {
					this.PaydetailShow = true
					this.accountLoading = true
					let data = {
						Id: Id
					}
					let result = await balanceRechargeRecordInfo(data)
					if (result.IsSuccess) {
						this.accoutInfo = result.Result
						this.accountLoading = false
					}
				} catch (err) {
					this.accountLoading = false
				} finally {
					this.accountLoading = false
				}
			},
			//获取数据
			async getList() {
				this.loading = true
				try {
					this.TimeValue = (this.TimeValue == null) ? [] : this.TimeValue
					let data = {
						KeyWords: this.searchKey,
						RecordState: this.changeType,
						StartTime: this.TimeValue[0],
						EndTime: this.TimeValue[1],
						Skip: (this.currentPage - 1) * this.pageSize,
						Take: this.pageSize,
					}

					let result = await balanceRechargeRecordlist(data)
					this.Total = result.Result.Total;
					this.groupData = result.Result.Results || [];
				} catch (e) {
					console.log('e', e)
				} finally {
					this.loading = false
				}
			},
			goodsSearch() {
				this.currentPage = 1
				this.getList();
			},
			async handleExport() {
				this.loading = true
				try {
					let url = this.exportUrl + '/pc/balanceRechargeRecord/export?' +
						'KeyWords=' + this.searchKey +
						'&RecordState=' + (this.changeType ? this.changeType : '') +
						'&StartTime=' + (this.TimeValue[0] ? this.TimeValue[0] : '') +
						'&EndTime=' + (this.TimeValue[1] ? this.TimeValue[1] : '')
					window.open(url);
				} catch (error) {
					console.log(error);
				} finally {
					this.loading = false
				}
			},
			// 切换显示条数
			handleSizeChange(val) {
				this.pageSize = val;
				this.getList();
			},

			// 翻页
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
			},
		},
	}
</script>

<style lang="less" scoped>
	.Paydetails {
		div {
			margin-bottom: 20px;
		}
	}
</style>
